import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import {
  Seo,
  Layout,
  HeaderTextOnly,
  RichText,
  FormEnterpriseContact,
  SectionTextWithImage,
  ButtonWithIcon,
  ButtonList,
} from "../components"
import { caseStudySlug } from "../utils"

type CaseStudy = {
  node: {
    contentful_id: string
    featuredImage: {
      gatsbyImageData: IGatsbyImageData
      title: string
    }
    listPageSummary: {
      raw: string
    }
    pageSections: any
    caseStudySections: any
  }
}

type CaseStudiesPage = {
  location: {
    href: string
  }
  data: {
    contentfulPageGeneric: {
      id: string
      heading: string
      header: {
        raw: string
      }
      slug: string
      pageSections: any
      seo: {
        description: {
          description: string
        }
        titleTemplate: {
          extension: string
        }
        title: string
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
    allContentfulPageCaseStudy: {
      edges: Array<CaseStudy>
    }
  }
}

const CaseStudies = ({ data, location }: CaseStudiesPage) => {
  const page = data.contentfulPageGeneric
  const caseStudies = data.allContentfulPageCaseStudy.edges
  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderTextOnly heading={page?.heading} alignment="center">
        <RichText content={page.header} variant="header-text-only" />
      </HeaderTextOnly>
      {caseStudies.map(({ node }: any, index: number) => (
        <SectionTextWithImage
          key={node.contentful_id}
          backgroundColour={index % 2 === 0 ? "grey-lightest" : "white"}
          heading={node.name}
          content={node.listPageSummary}
          image={node?.featuredImage?.gatsbyImageData}
          imageAlt={node?.featuredImage?.title}
          showImageFirst={index % 2 === 0 ? false : true}
          imageBlobBackground={false}
          showSignUpButton={false}
          scheduleDemoButton={false}
        >
          <ButtonList>
            <ButtonWithIcon to={caseStudySlug(node.slug)} style="secondary">
              Find out more
            </ButtonWithIcon>
          </ButtonList>
        </SectionTextWithImage>
      ))}
      <FormEnterpriseContact />
    </Layout>
  )
}

export default CaseStudies

export const pageQuery = graphql`
  query getCaseStudiesData {
    contentfulPageGeneric(name: { eq: "Case studies" }) {
      id
      heading
      header {
        raw
      }
      seo {
        description {
          description
        }
        titleTemplate {
          extension
        }
        title
        image {
          fixed {
            src
          }
        }
      }
    }
    allContentfulPageCaseStudy {
      edges {
        node {
          contentful_id
          name
          slug
          featuredImage {
            gatsbyImageData
            title
          }
          companyLogo {
            gatsbyImageData
            title
          }
          listPageSummary {
            raw
          }
        }
      }
    }
  }
`
